.ant-form-horizontal .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bp-login-button {
  background-color: #2800ef !important;
  border-color: #2000bf !important;
}

.bp-login-button:hover {
  background-color: #2000bf !important;
  border-color: #2000bf !important;
}

.bp-login-button:focus {
  background-color: #2000bf !important;
  border-color: #2000bf !important;
}

.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active {
  background-color: #2000bf !important;
  border-color: #2000bf !important;
}
