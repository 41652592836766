.gx-bg-weekly {
  background: linear-gradient(
    90deg,
    rgba(48, 119, 189, 1) 0%,
    rgba(0, 17, 51, 1) 0%,
    rgba(0, 188, 212, 1) 100%
  );
}
.tada-animation {
  animation: tada 2s ease 3;
  -webkit-animation: tada 2s ease 3;
  display: inline-block;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%,
  20% {
    transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scaleX(1) rotate(10deg);
  }

  40%,
  60%,
  80% {
    transform: rotate(-10deg);
  }

  100% {
    transform: scaleX(1);
  }
}
